import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row,
    FormFeedback,
} from 'reactstrap';

const ActivateCardModal = ({ isOpen, toggle, cardData, handleSubmit }) => {
    const validationSchema = Yup.object().shape({
        lastFour: Yup.string()
            .required('*Please enter the last 4 digits of your card')
            .length(4, '*Must be exactly 4 digits')
            .matches(/^\d+$/, '*Must be only digits')
            .test('match-last-four', '*Last 4 digits do not match', function(value) {
                return value === cardData.lastFour;
            }),
        cvv: Yup.string()
            .required('*Please enter the CVV on the back of your card')
            .length(3, '*Must be exactly 3 digits'),
        month: Yup.string()
            .required('*Please enter the expiration month')
            .length(2, '*Must be exactly 2 digits')
            .matches(/^(0[1-9]|1[0-2])$/, '*Invalid month'),
        year: Yup.string()
            .required('*Please enter the expiration year')
            .length(4, '*Must be exactly 4 digits')
            .matches(/^\d{4}$/, '*Invalid year'),
    });

    const formik = useFormik({
        initialValues: {
            lastFour: '',
            cvv: '',
            month: '',
            year: '',
        },
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Activate Card</ModalHeader>
            <Form onSubmit={formik.handleSubmit}>
                <ModalBody>
                    <p>To activate a card, please confirm the following informaiton.</p>
                    <FormGroup>
                        <Row>
                            <Col xs="12" md="6">
                                <Label for="lastFour">
                                    <b>Last 4 Digits of Card #:</b>
                                </Label>
                                <Input
                                    type="text"
                                    name="lastFour"
                                    id="lastFour"
                                    value={formik.values.lastFour}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.errors.lastFour && formik.touched.lastFour}
                                    placeholder="1234"
                                    required
                                />
                                {formik.errors.lastFour && formik.touched.lastFour ? (
                                    <FormFeedback invalid={formik.errors.lastFour && formik.touched.lastFour} tooltip>
                                        {formik.errors.lastFour}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                            <Col xs="12" md="6">
                                <Label for="cvv">
                                    <b>CVV:</b>
                                </Label>
                                <Input
                                    type="text"
                                    name="cvv"
                                    id="cvv"
                                    value={formik.values.cvv}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="123"
                                    invalid={formik.errors.cvv && formik.touched.cvv}
                                    required
                                />
                                {formik.errors.cvv && formik.touched.cvv ? (
                                    <FormFeedback invalid={formik.errors.cvv && formik.touched.cvv} tooltip>
                                        {formik.errors.cvv}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <p>
                            <b>Expiration Date:</b>
                        </p>
                        <Row>
                            <Col xs="12" md="6">
                                <Label for="month">Month:</Label>
                                <Input
                                    type="text"
                                    name="month"
                                    id="month"
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.errors.month && formik.touched.month}
                                    placeholder="MM"
                                    required
                                />
                                {formik.errors.month && formik.touched.month ? (
                                    <FormFeedback invalid={formik.errors.month && formik.touched.month} tooltip>
                                        {formik.errors.month}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                            <Col xs="12" md="6">
                                <Label for="year">Year:</Label>
                                <Input
                                    type="text"
                                    name="year"
                                    id="year"
                                    placeholder="YYYY"
                                    value={formik.values.year}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.errors.year && formik.touched.year}
                                    required
                                />
                                {formik.errors.year && formik.touched.year ? (
                                    <FormFeedback invalid={formik.errors.year && formik.touched.year} tooltip>
                                        {formik.errors.year}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={
                            formik.errors.lastFour || formik.errors.cvv || formik.errors.month || formik.errors.year
                        }>
                        Activate Card
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default ActivateCardModal;
